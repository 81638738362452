* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #fff;
  /* background: #5173a0; */
  background: #f5f8fa;
}

body, input, button, textarea, select {
  font: 600 18px Nunito, sans-serif;
}

input, textarea {
  outline: none;
}

button {
  outline: none;
}
